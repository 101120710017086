import classNames from "classnames";
import { ChampionSkill } from "@outplayed/tooltips";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useCounterTips } from "@ugg/shared/api/requests/champions/counter-tips";
import { HeaderBullet } from "@ugg/shared/components/common/HeaderBullet";

type CounterTipsType = {
  adv: string[];
  disadv: string[];
  id: string;
};

export function CounterTips(props: { className?: string; championId: number }) {
  const { className, championId } = props;
  const { getChampionName } = getRiotAssetsContext();
  const { data: counterTips } = useCounterTips({ ssr: true });

  const parseText = (text: string, index: number): (JSX.Element | string)[] => {
    if (!text) return [];

    for (let i = 0; i < text.length; i++) {
      if (text.charAt(i) === "{") {
        if (i !== 0) {
          return [...text.slice(0, i), ...parseText(text.slice(i), index + 1)];
        }
      } else if (text.charAt(i) === "}") {
        const value = text.slice(1, i);
        const skillMap: { [key: string]: number } = { Q: 0, W: 1, E: 2, R: 3 };
        const inlineSkill = (
          <ChampionSkill key={index} championId={championId} skillSlot={skillMap[value]} isPassive={value === "P"} ssr>
            {({ imgSrc, skillName }) => (
              <div className="relative top-[2px] inline-flex items-center p-[3px] h-[17px] rounded-[1px] bg-purple-500">
                <img className="mr-[3px] w-[11px]" src={imgSrc} alt={skillName} />
                <div
                  className={classNames("text-[11px] font-bold select-none", {
                    "text-[#ff9b00]": value === "Q",
                    "text-[#3273fa]": value === "W",
                    "text-[#31db9e]": value === "E",
                    "text-[#ff4e50]": value === "R",
                  })}
                >
                  {value}
                </div>
              </div>
            )}
          </ChampionSkill>
        );
        return [inlineSkill, ...parseText(text.slice(i + 1), index + 1)];
      }
    }

    return [text];
  };

  const championName = getChampionName(championId);
  const { adv, disadv } = counterTips?.[championId] || {};
  return (
    <div className={classNames("p-[18px] bg-purple-400 rounded-[3px]", className)}>
      <HeaderBullet className="flex items-center justify-between mb-[18px]">
        <div>Playing vs {championName}</div>
        <div className="text-accent-gray-100 text-[14px] font-normal leading-[20px]">1 min read</div>
      </HeaderBullet>
      <div className="flex flex-wrap gap-[12px]">
        {adv && adv.length > 0 && (
          <div className="flex-[1_1_400px] p-[18px] bg-purple-200 rounded-[2px]">
            <div className="mb-[12px] text-[14px] font-bold leading-[20px]">Winning Matchup</div>
            <div className="flex flex-col gap-[14px]">
              {adv.map((text, index) => (
                <div key={index} className="whitespace-pre-wrap text-[12px] text-accent-gray-900 leading-[17px]">
                  {parseText(text, 0)}
                </div>
              ))}
            </div>
          </div>
        )}
        {disadv && disadv.length > 0 && (
          <div className="flex-[1_1_400px] p-[18px] bg-purple-200 rounded-[2px]">
            <div className="mb-[12px] text-[14px] font-bold leading-[20px]">Losing Matchup</div>
            <div className="flex flex-col gap-[14px]">
              {disadv.map((text, index) => (
                <div key={index} className="whitespace-pre-wrap text-[12px] text-accent-gray-900 leading-[17px]">
                  {parseText(text, 0)}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
