import { Switch, Route, Redirect } from "react-router-dom";
import { AppRoutes } from "@ugg/shared/routes/app-routes";
import loadable from "@loadable/component";

const Overview = loadable(() => import("components/Champions/Overview"), {
  resolveComponent: (components) => components.Overview,
});
const CountersContainer = loadable(() => import("@ugg/shared/components/Champions/pages/Counters/CountersContainer"), {
  resolveComponent: (components) => components.CountersContainer,
});
const Matchups = loadable(() => import("@ugg/shared/components/Champions/pages/Matchups/Matchups"), {
  resolveComponent: (components) => components.Matchups,
});
const ItemsTableContainer = loadable(() => import("@ugg/shared/components/Champions/pages/ItemsTable/ItemsTableContainer"), {
  resolveComponent: (components) => components.ItemsTableContainer,
});
const ItemPathsContainer = loadable(() => import("@ugg/shared/components/Champions/pages/ItemPaths/ItemPathsContainer"), {
  resolveComponent: (components) => components.ItemPathsContainer,
});
const RunesTableContainer = loadable(() => import("@ugg/shared/components/Champions/pages/RunesTable/RunesTableContainer"), {
  resolveComponent: (components) => components.RunesTableContainer,
});
const RuneSetsContainer = loadable(() => import("@ugg/shared/components/Champions/pages/RuneSets/RuneSetsContainer"), {
  resolveComponent: (components) => components.RuneSetsContainer,
});
const SpellsAbilitiesContainer = loadable(
  () => import("@ugg/shared/components/Champions/pages/SpellsAbilities/SpellsAbilitiesContainer"),
  {
    resolveComponent: (components) => components.SpellsAbilitiesContainer,
  },
);
const ChampionDuos = loadable(() => import("@ugg/shared/components/Champions/pages/Duos/ChampionDuos"), {
  resolveComponent: (components) => components.ChampionDuos,
});

export function ChampionProfileRoutes() {
  return (
    <Switch>
      <Route path={AppRoutes.CHAMPION_PROFILE_COUNTERS} component={CountersContainer} />
      <Route path={AppRoutes.CHAMPION_PROFILE_MATCHUPS} component={Matchups} />
      <Route path={AppRoutes.CHAMPION_PROFILE_ITEMS} component={ItemsTableContainer} />
      <Route path={AppRoutes.CHAMPION_PROFILE_ITEM_PATHS} component={ItemPathsContainer} />
      <Route path={AppRoutes.CHAMPION_PROFILE_RUNES_TABLE} component={RunesTableContainer} />
      <Route path={AppRoutes.CHAMPION_PROFILE_RUNE_SETS} component={RuneSetsContainer} />
      <Route path={AppRoutes.CHAMPION_PROFILE_SPELLS_ABILITIES} component={SpellsAbilitiesContainer} />
      <Route path={AppRoutes.CHAMPION_PROFILE_DUOS} component={ChampionDuos} />
      {/*  
        Keep overview routes last because special game mode routes can conflict with above routes
        (e.g. CHAMPION_PROFILE_ITEM_PATHS, CHAMPION_PROFILE_RUNES_TABLE)
      */}
      <Route path={[AppRoutes.CHAMPION_PROFILE_BUILD, AppRoutes.CHAMPION_PROFILE_BUILD_SPECIAL_GAME_MODE]} component={Overview} />
      <Redirect from={AppRoutes.CHAMPION_PROFILE_ROOT} to={AppRoutes.CHAMPION_PROFILE_BUILD} />
    </Switch>
  );
}
