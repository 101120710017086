import { useUGGAssetsContext } from "@ugg/shared/components/UGGAssetsProvider";
import { JSONFetcherOptions, useJSONFetcher } from "@outplayed/json-fetcher";

type CounterTipsType = {
  adv: string[];
  disadv: string[];
  id: string;
};

export function useCounterTips(options: JSONFetcherOptions = {}) {
  const { staging } = useUGGAssetsContext();
  const envDir = staging ? "staging" : "prod";
  const url = `https://static.bigbrain.gg/assets/lol/riot_patch_update/${envDir}/ugg/counter-tips.json`;
  return useJSONFetcher<Record<number, CounterTipsType>>(url, options);
}
