import classNames from "classnames";
import { getNotFoundImg } from "@outplayed/riot-assets";

export const ChampionProfilePageError = ({ className, message }: { className?: string; message?: string | JSX.Element }) => {
  return (
    <div className={classNames("flex items-center flex-col w-full py-[60px] px-[12px] bg-purple-400 rounded-[3px]", className)}>
      <img className="h-[200px]" src={getNotFoundImg()} alt="Oops! Something is wrong!" />
      <div className="mt-[50px] mb-[30px] text-[16px] font-medium text-white text-center">
        {message || "Oops! Looks like theres no data here. Please try again later."}
      </div>
    </div>
  );
};
