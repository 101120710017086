import classNames from "classnames";
import { TooltipContainer } from "@outplayed/tooltips";

interface YellowAlertProps {
  className?: string;
  label: string;
  width?: number;
}

export default function YellowAlert(props: YellowAlertProps) {
  const { className, label, width } = props;
  return (
    <TooltipContainer tooltipInfo={label}>
      <div className={classNames("ugg-alert", className)}>
        <img width={width} src={"https://static.bigbrain.gg/assets/ugg/icons/alert-yellow.svg"} />
      </div>
    </TooltipContainer>
  );
}
